// by @GabiThume (https://github.com/gabithume)
$color-background: #e2e0de;
$color-footer-mobile-1: darken($color-background, 2%);
$color-footer-mobile-2: darken($color-background, 10%);
$color-background-code: darken($color-background, 2%);
$color-border: #666;
$color-meta: #666;
$color-meta-code: lighten($color-meta, 10%);
$color-link: rgba(43, 188, 138, 1);
$color-text: #363533;
$color-accent-3: #666666;
$color-accent-2: #111111;
$color-accent-1: #d44375;
$color-quote: #ab2251;